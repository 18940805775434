$white:    #fff;
$red:     #dc3545;
$dark-gray: #4b4a4a;
$light-gray: #bdbdbd;
$black: #000000;
$body-bg: $white;
$body-color: $dark-gray;
$tym-color-bordeaux: #800000;

/* custom vars */
$custom-font-size: clamp (0.6rem, 2vw, 1.4rem);
