@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap";

.content {
  font-size: $custom-font-size;
}

.main-image-container {
    display: inline-block;
    width: 100%;
    position: relative;
    max-height: 500px;
    overflow: hidden;
    object-fit: cover;
}

.small-image {
  float: right;
  width: 40%;
  padding: 2%;
}

.tym-h2 {
  font-size: large;
  color: $tym-color-bordeaux;
  padding-bottom: 2%;
  padding-top: 2%;
}

.tym-page-headline {
  font-size: large;
  font-weight: normal;
  text-align: center;
  font-style: italic;
  color: $tym-color-bordeaux;
  padding-bottom: 5%;
}

.tym-page-headline-left {
  font-size: large;
  font-weight: normal;
  text-align: left;
  font-style: italic;
  color: $tym-color-bordeaux;
}

.container {
  padding-bottom: 10%;
}

.footer-link {
  float: right;
  padding-right: 5%;
  text-decoration: none;
}

.footer-social {
  display: flex;
  padding-left: 40%;
}


a:link { color: $light-gray; }
a:active { color: $light-gray; }
a:visited { color: $light-gray; }
a:hover {color: $tym-color-bordeaux;}

p {
}

.custom-table th, .custom-table td {
  padding-right: 40px;
}

.navbar-tym {
  justify-content: center;
}

.navbar-item {
  float: left;
  margin-right: 20px;
}

.navbar-brand {
  padding-top: 5%;
  font-size: 2vw;
}

.navbar-brand img {
  width: 2.5vmax;
}

.navbar-right {
  position: fixed;
  right: 0;
  float:inline-end;
}

.nav-item {
  color: $light-gray;
}

.nav-item:link { color: $light-gray; }
.nav-item:active { color: $light-gray; }
.nav-item:after { color: $light-gray; }
.nav-item:hover {color: $tym-color-bordeaux;}

.navbar-toggler-icon{
  font-size: 0.8em;
}

.offcanvas {
	background-color: black;
  opacity: 80%;
}
